import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import {
  DataTable,
  TableContainer,
  TableBatchActions,
  TableBatchAction,
  TableToolbar,
  Table,
  Modal,
  Checkbox,
  InlineLoading,
} from "carbon-components-react";
import { InlineNotification } from "gatsby-theme-carbon";

import { getQuestionsData, getHeaderData } from "../data/";

import {
  presetAddedQuestions,
  formatQuestionsData,
  formatTaxonomies,
  docxExport,
  shuffle,
} from "../util";

import {
  QuestionsToolbarContent,
  QuestionPreviewModal,
  QuestionsTableHead,
  QuestionsTableBody,
} from "../components";
import { DocumentSubtract16 } from "@carbon/icons-react";

const headerData = getHeaderData();

export default function CustomTest({ data, pageContext }) {
  const [categoryFilters, setCategoryFilters] = useState([]);
  const isCuratedTest =
    typeof pageContext.isCuratedTest !== "undefined" && pageContext.isCuratedTest;
  let curated_test =
    pageContext.id &&
    data.allNodeTest &&
    data.allNodeTest.edges &&
    data.allNodeTest.edges.length > 0
      ? data.allNodeTest.edges.find(({ node }) => node.drupal_id === pageContext.id)
      : null;
  const curatedQuestions =
    typeof curated_test !== "undefined" && curated_test
      ? curated_test.node.relationships.field_questions.map(({ drupal_id }, i) => ({
          id: drupal_id,
          order: i + 1,
        }))
      : null;
  const [addedQuestions, setAddedQuestions] = useState(
    presetAddedQuestions(curatedQuestions)
  );
  const [rowsData, setRowsData] = useState([]);
  const [sortedRowsData, setSortedRowsData] = useState([]);
  const [previewing, setPreviewing] = useState(null);
  const [previewQuestionObj, setPreviewQuestionObj] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [downloadAgreeValidation, setDownloadAgreeValidation] = useState(null);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const testTitle = pageContext.title ? pageContext.title : "Custom Test";

  useEffect(() => {
    formatTaxonomies(data, setCategoriesData, setKeywordsData);
  }, [data]);

  useEffect(() => {
    if (categoriesData.length > 0 && keywordsData.length > 0) {
      if (
        data.allNodeQuestion &&
        data.allNodeQuestion.edges &&
        data.allNodeQuestion.edges.length > 0
      ) {
        setQuestionsData(getQuestionsData(data.allNodeQuestion, data.site));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesData, keywordsData]);

  useEffect(() => {
    if (questionsData.length > 0) {
      let added_obj =
        typeof localStorage !== "undefined" && !isCuratedTest
          ? localStorage.getItem("addedQuestions")
          : curatedQuestions;
      if (typeof added_obj === "string") added_obj = JSON.parse(added_obj);
      if (added_obj) {
        let added_ids = added_obj.map((q) => q.id);
        let added_questions = questionsData.filter((question_data) =>
          added_ids.includes(question_data.id)
        );
        added_questions = added_questions.map((q) => {
          let found_added_obj = added_obj.find((f) => f.id === q.id);
          let order_result = null;

          if (found_added_obj && typeof found_added_obj.order === "number")
            order_result = parseInt(found_added_obj.order);

          return {
            ...q,
            order: order_result === null ? q.order : order_result,
          };
        });
        if (added_questions) {
          let rows_data = formatQuestionsData(
            added_questions,
            categoriesData,
            keywordsData,
            setPreviewing
          );
          setRowsData(rows_data);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsData]);

  useEffect(() => {
    if (addedQuestions.length === 0 && !isCuratedTest) {
      if (typeof localStorage !== "undefined") {
        localStorage.removeItem("addedQuestions");
      }
      setSortedRowsData([]);
    } else {
      if (!isCuratedTest && typeof localStorage !== "undefined") {
        let added_str = JSON.stringify(addedQuestions);
        localStorage.setItem("addedQuestions", added_str);
      }
      let result = [];
      if (rowsData.length > 0) {
        let added_ids = addedQuestions.map((q) => q.id);
        result = rowsData.filter((question) => added_ids.includes(question.id));
        result = result.map((question) => {
          let added_questions_item = addedQuestions.find((q) => q.id === question.id);
          return {
            ...question,
            order: added_questions_item.order,
          };
        });
      }
      setSortedRowsData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedQuestions, rowsData]);

  useEffect(() => {
    let filtered = [];
    if (categoryFilters.length > 0 && categoryFilters.length < categoriesData.length) {
      filtered = rowsData.filter((question) => {
        return categoryFilters.some((cat_id) => {
          return question.category_ids.includes(cat_id);
        });
      });
    } else {
      filtered = rowsData;
    }
    filtered = filtered.map((question) => {
      let added_questions_item = addedQuestions.find((q) => q.id === question.id);
      return {
        ...question,
        order: added_questions_item.order,
      };
    });
    setSortedRowsData(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilters]);

  useEffect(() => {
    let sorted = sortedRowsData.sort((a, b) => parseInt(a.order) - parseInt(b.order));
    setSortedRowsData(sorted);
  }, [sortedRowsData]);

  useEffect(() => {
    if (previewing) {
      let previewing_question = sortedRowsData.find(
        (question) => question.id === previewing
      );
      if (previewing_question) {
        setPreviewQuestionObj(previewing_question);
      } else {
        setPreviewQuestionObj(null);
      }
    } else {
      setPreviewQuestionObj(null);
    }
  }, [previewing, sortedRowsData]);

  function toggleCategoryFilter(checked, id) {
    if (checked) {
      setCategoryFilters([...categoryFilters, id]);
    } else {
      setCategoryFilters(categoryFilters.filter((cat_id) => cat_id !== id));
    }
  }

  function randomizeQuestions() {
    if (categoriesData.length > 0 && keywordsData.length > 0) {
      if (
        data.allNodeQuestion &&
        data.allNodeQuestion.edges &&
        data.allNodeQuestion.edges.length > 0
      ) {
        setQuestionsData(getQuestionsData(data.allNodeQuestion, data.site));
      }
    }

    let shuffled_ids = shuffle(addedQuestions.map((q) => q.id));
    let shuffled_questions = shuffled_ids.map((id, i) => ({
      id,
      order: i + 1,
    }));
    setAddedQuestions(shuffled_questions);
  }

  function onPreviewSubmit(e) {
    if (previewQuestionObj !== null) {
      let added_questions = addedQuestions.filter((q) => q.id !== previewQuestionObj.id);
      setAddedQuestions(added_questions);
      if (added_questions.length === 0) {
        setRowsData([]);
      }
      setPreviewing(null);
    }
  }

  return (
    <div className={isDragging ? "dragging" : ""}>
      <DataTable
        rows={sortedRowsData}
        headers={headerData}
        render={({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          getBatchActionProps,
          onInputChange,
          selectedRows,
        }) => {
          return (
            <>
              {rowsData.length === 0 && (
                <>
                  <InlineNotification className="empty-test-notification">
                    Questions you add to your custom test will appear here.
                  </InlineNotification>
                </>
              )}

              {rowsData.length > 0 && (
                <div className="bx--row">
                  <TableContainer id="questions-data-table">
                    <TableToolbar>
                      <TableBatchActions {...getBatchActionProps()}>
                        <TableBatchAction
                          tabIndex={getBatchActionProps().shouldShowBatchActions ? 0 : -1}
                          renderIcon={DocumentSubtract16}
                          onClick={(e) => {
                            let selected_ids = selectedRows.map((a_row) => a_row.id);
                            let added_questions = addedQuestions.filter(
                              (q) => !selected_ids.includes(q.id)
                            );
                            setAddedQuestions(added_questions);
                            if (added_questions.length === 0) {
                              setRowsData([]);
                            }
                            getBatchActionProps().onCancel();
                          }}
                        >
                          Remove from test
                        </TableBatchAction>
                      </TableBatchActions>
                      <QuestionsToolbarContent
                        getBatchActionProps={getBatchActionProps}
                        onInputChange={onInputChange}
                        categoryFilters={categoryFilters}
                        toggleCategoryFilter={toggleCategoryFilter}
                        setCategoryFilters={setCategoryFilters}
                        isCustomTest={true}
                        setAddedQuestions={setAddedQuestions}
                        addedQuestions={addedQuestions}
                        setDownloading={setDownloading}
                        categoriesData={categoriesData}
                        randomizeQuestions={randomizeQuestions}
                      />
                    </TableToolbar>
                    <Table>
                      <QuestionsTableHead
                        headers={headers}
                        getSelectionProps={getSelectionProps}
                        getHeaderProps={getHeaderProps}
                        withSelection={!isCuratedTest}
                      />
                      <QuestionsTableBody
                        rows={rows}
                        getSelectionProps={getSelectionProps}
                        getRowProps={getRowProps}
                        isCustomTest={true}
                        setAddedQuestions={setAddedQuestions}
                        addedQuestions={addedQuestions}
                        withSelection={!isCuratedTest}
                        setIsDragging={setIsDragging}
                      />
                    </Table>
                    <QuestionPreviewModal
                      previewQuestionObj={previewQuestionObj}
                      setPreviewing={setPreviewing}
                      previewing={previewing}
                      onPreviewSubmit={onPreviewSubmit}
                      primaryButtonText="Remove from test"
                    />
                  </TableContainer>
                </div>
              )}
              <Modal
                size="sm"
                modalHeading="Required confirmation"
                primaryButtonText={
                  loadingDownload ? (
                    <>
                      {"Downloading..."} <InlineLoading />
                    </>
                  ) : (
                    "Download"
                  )
                }
                secondaryButtonText="Close"
                modalLabel="Download Consent"
                open={downloading}
                primaryButtonDisabled={loadingDownload}
                onRequestClose={(e) => {
                  if (loadingDownload) {
                    e.preventDefault();
                  } else {
                    setDownloading(false);
                    setDownloadAgreeValidation(null);
                    setAgreeChecked(false);
                  }
                }}
                onRequestSubmit={(e) => {
                  if (!agreeChecked) {
                    setDownloadAgreeValidation(false);
                  } else {
                    setDownloadAgreeValidation(null);
                    setLoadingDownload(true);
                    let d = new Date();
                    docxExport(
                      addedQuestions,
                      questionsData,
                      `${testTitle} - ${d.toTimeString()}`,
                      false,
                      data.allFileFile
                    ).then(() => {
                      setTimeout(() => {
                        docxExport(
                          addedQuestions,
                          questionsData,
                          `Answers - ${testTitle} - ${d.toTimeString()}`,
                          true
                        ).then(() => {
                          setTimeout(() => {
                            setLoadingDownload(false);
                            setDownloading(false);
                            setDownloadAgreeValidation(null);
                            setAgreeChecked(false);
                          }, 1000);
                        });
                      }, 1000);
                    });
                  }
                }}
              >
                <div id="download-consent">
                  {downloadAgreeValidation === false && (
                    <InlineNotification kind="error">
                      Please agree to proceed with the download
                    </InlineNotification>
                  )}
                  <p>
                    By accessing and using the questions on this website, you agree to
                    keep all questions and answers confidential and in a secure location
                    to protect the integrity of the exam questions and the work of those
                    who have written them.
                  </p>
                  <fieldset className="bx--fieldset">
                    <Checkbox
                      disabled={loadingDownload}
                      labelText=" I confirm that questions will not be shared to help protect the integrity of the test content."
                      id="checked-consent"
                      checked={agreeChecked}
                      onChange={(e) => {
                        setAgreeChecked(e);
                      }}
                    />
                  </fieldset>
                </div>
              </Modal>
            </>
          );
        }}
      />
    </div>
  );
}

export const query = graphql`
  {
    allSitePage(filter: { path: { eq: "/custom-test/" } }) {
      edges {
        node {
          id
          path
          fields {
            frontmatter {
              description
              keywords
              tabs
              title
            }
          }
        }
      }
    }
    allTaxonomyTermCategories {
      edges {
        node {
          drupal_id
          name
          field_color
        }
      }
    }
    allTaxonomyTermTags {
      edges {
        node {
          drupal_id
          name
        }
      }
    }
    allNodeQuestion {
      edges {
        node {
          drupal_id
          title
          field_answer {
            processed
          }
          field_question {
            processed
          }
          field_wrong_answers {
            processed
          }
          relationships {
            field_categories {
              drupal_id
            }
            field_keywords {
              drupal_id
            }
          }
        }
      }
    }
    allNodeTest {
      edges {
        node {
          drupal_id
          title
          relationships {
            field_questions {
              drupal_id
            }
          }
        }
      }
    }
    allFileFile {
      edges {
        node {
          drupal_id
          localFile {
            url
            size
          }
        }
      }
    }
    site {
      siteMetadata {
        drupalUrl
      }
    }
  }
`;
